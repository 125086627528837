import { DailyQuestion, EventType, getDailyQuestions, getEventTypes } from '@/api/modules/points'
import { createStore } from '@/helpers'

type PointsState = {
  eventTypes: EventType[]
  dailyQuestions: DailyQuestion[]
}

const [pointsStore, usePointsState] = createStore(
  'points',
  {
    eventTypes: [],
    dailyQuestions: [],
  } as PointsState,
  state => ({
    async loadEventTypes() {
      const { data } = await getEventTypes()
      state.eventTypes = data
    },

    async loadDailyQuestions() {
      const { data } = await getDailyQuestions()
      state.dailyQuestions = data
    },
  }),
  { isPersist: false },
)

export { pointsStore, usePointsState }
