import { api } from '@/api/clients'
import { ApiServicePaths } from '@/enums'

import { CreateDailyQuestion, DailyQuestion, UpdateDailyQuestion } from '../types'

export async function getDailyQuestions() {
  return api.get<DailyQuestion[]>(`${ApiServicePaths.Points}/v1/public/daily_questions/admin`)
}

export async function createDailyQuestion(attributes: CreateDailyQuestion['attributes']) {
  return api.post<DailyQuestion>(`${ApiServicePaths.Points}/v1/public/daily_questions/admin`, {
    body: {
      data: {
        type: 'daily_questions',
        attributes,
      },
    },
  })
}

export async function updateDailyQuestion({ id, attributes }: UpdateDailyQuestion) {
  return api.patch<DailyQuestion>(
    `${ApiServicePaths.Points}/v1/public/daily_questions/admin/${id}`,
    {
      body: {
        data: {
          id,
          type: 'daily_questions',
          attributes,
        },
      },
    },
  )
}

export async function deleteDailyQuestion(id: string) {
  return api.delete<DailyQuestion>(
    `${ApiServicePaths.Points}/v1/public/daily_questions/admin/${id}`,
  )
}
