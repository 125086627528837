import { api } from '@/api/clients'
import { ApiServicePaths } from '@/enums'

import { CreateEventType, EventType, UpdateEventType } from '../types'

export async function getEventTypes() {
  return api.get<EventType[]>(`${ApiServicePaths.Points}/v1/public/event_types/qr`, {
    query: { count: true },
  })
}

export async function createEventType({ id, ...attributes }: CreateEventType) {
  return api.post<EventType>(`${ApiServicePaths.Points}/v1/public/event_types`, {
    body: {
      data: {
        id,
        type: 'event_type',
        attributes,
      },
    },
  })
}

export async function updateEventType({ id, ...attributes }: UpdateEventType) {
  return api.put<EventType>(`${ApiServicePaths.Points}/v1/public/event_types/${id}`, {
    body: {
      data: {
        id,
        type: 'event_type',
        attributes: {
          ...attributes,
          qr_code_value: undefined,
        },
      },
    },
  })
}
