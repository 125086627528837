import packageJson from '../package.json'

export type Config = {
  APP_NAME: string
  APP_HOST_URL: string
  API_URL: string
  BUILD_VERSION: string
  TERMS_LINK: string
  PRIVACY_NOTICE_LINK: string
  APP_STORE_APP_LINK: string
  GOOGLE_PLAY_APP_LINK: string
  DEFERRED_DEEP_LINK: string
}

export const config: Config = {
  APP_NAME: import.meta.env.VITE_APP_NAME,
  APP_HOST_URL: import.meta.env.VITE_APP_HOST_URL,
  API_URL: import.meta.env.VITE_API_URL,
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,
  TERMS_LINK: '/general-terms.html',
  PRIVACY_NOTICE_LINK: '/privacy-notice.html',
  APP_STORE_APP_LINK: 'https://apps.apple.com/app/unitedgsh/id6544793145',
  GOOGLE_PLAY_APP_LINK: 'https://play.google.com/store/apps/details?id=org.freedomtool.unitedgsh',
  DEFERRED_DEEP_LINK: 'https://unitedspace.onelink.me/omDr/ref',
}
