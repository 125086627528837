import { api } from '@/api/clients'
import { AuthTokensGroup } from '@/api/modules/auth'
import { ApiServicePaths } from '@/enums'

export const authorizeAdmin = async (password: string) => {
  const { data } = await api.post<AuthTokensGroup>(`${ApiServicePaths.Auth}/v1/authorize/admin`, {
    body: {
      data: {
        type: 'authorize_admin',
        attributes: { password },
      },
    },
  })

  return data
}

export const refreshJwt = async () => {
  const { data } = await api.get<AuthTokensGroup>(`${ApiServicePaths.Auth}/v1/refresh`)
  return data
}
