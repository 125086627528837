import { Box, Button, Stack, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

import { config } from '@/config'
import { Icons } from '@/enums'
import { vh } from '@/theme/helpers'
import { UiIcon } from '@/ui'

const AuthLayout = ({ children }: PropsWithChildren) => {
  const { spacing } = useTheme()

  return (
    <Stack direction='row' height={vh(100)}>
      <Stack
        py={8}
        flex={1}
        sx={{
          pl: { xs: 4, md: 14 },
          pr: { xs: 4, md: 8 },
        }}
      >
        <Box flex={1} position='relative'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <UiIcon name={Icons.App} size={8} />
            <Stack direction='row' spacing={4}>
              <Button
                component='a'
                href={config.TERMS_LINK}
                target='_blank'
                variant='text'
                color='secondary'
                size='medium'
              >
                <Stack spacing={1} direction='row' alignItems='center'>
                  Terms
                  <UiIcon name={Icons.ArrowUp} size={4} sx={{ transform: 'rotate(45deg)' }} />
                </Stack>
              </Button>
              <Button
                component='a'
                href={config.PRIVACY_NOTICE_LINK}
                target='_blank'
                variant='text'
                color='secondary'
                size='medium'
              >
                <Stack spacing={1} direction='row' alignItems='center'>
                  Privacy
                  <UiIcon name={Icons.ArrowUp} size={4} sx={{ transform: 'rotate(45deg)' }} />
                </Stack>
              </Button>
            </Stack>
          </Stack>

          <Stack
            position='absolute'
            top='50%'
            left='50%'
            width='100%'
            maxWidth={spacing(100)}
            sx={{ transform: 'translate(-50%, -50%)' }}
          >
            {children}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}

export default AuthLayout
