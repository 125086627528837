import { api } from '@/api/clients'
import { authorizeAdmin } from '@/api/modules/auth'
import { createStore } from '@/helpers'
import { parseJwt } from '@/helpers/jwt'

type AuthState = {
  accessToken: string
  refreshToken: string
}

const [authStore, useAuthState] = createStore(
  'auth',
  {
    accessToken: '',
    refreshToken: '',
  } as AuthState,
  state => ({
    async signIn(password: string) {
      const data = await authorizeAdmin(password)

      state.accessToken = data.access_token.token
      state.refreshToken = data.refresh_token.token

      this.addAuthInterceptor()
    },
    signOut() {
      state.accessToken = ''
      state.refreshToken = ''
      api.clearInterceptors()
    },
    verifyToken() {
      const parsedJwt = parseJwt(state.accessToken)
      if (!parsedJwt || parsedJwt.exp < Date.now() / 1000) {
        this.signOut()
      }

      this.addAuthInterceptor()
    },
    addAuthInterceptor() {
      api.addInterceptor({
        request: async config => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${state.accessToken}`,
          }
          return config
        },
        response: async response => {
          if (response.status === 401) {
            this.signOut()
          }
          return response
        },
      })
    },
  }),
)

export { authStore, useAuthState }
