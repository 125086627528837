import { Stack, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

import { AppNavbar } from '@/common'
import { vh } from '@/theme/helpers'

const MainLayout = ({ children }: PropsWithChildren) => {
  const { spacing, breakpoints } = useTheme()

  return (
    <Stack
      direction='row'
      spacing={4}
      height={vh(100)}
      width='100%'
      sx={{
        [breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: 0,
        },
      }}
    >
      <AppNavbar />
      <Stack
        py={8}
        flex={1}
        overflow='hidden auto'
        sx={{
          px: 0,
          [breakpoints.down('md')]: { px: 4, py: 4 },
        }}
      >
        <Stack mx='auto' maxWidth={spacing(220)} width='100%'>
          {children}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MainLayout
