import { alpha } from '@mui/material'
import { PaletteOptions } from '@mui/material/styles'

export const lightPalette: PaletteOptions = {
  mode: 'light',
  common: {
    black: '#000000', // base/black
    white: '#FFFFFF', // base/white
  },
  primary: {
    darker: '#0A4C3D', // primary/primary-darker
    dark: '#0D5847', // primary/primary-dark
    main: '#136854', // primary/primary-base
    light: '#B4EFE1', // primary/primary-light
    lighter: '#DAFEF5', // primary/primary-lighter
    contrastText: '#000000',
  },
  secondary: {
    darker: '#C897E6', // secondary/secondary-darker
    dark: '#D9AEF3', // secondary/secondary-dark
    main: '#E7C1FE', // secondary/secondary-base
    light: '#F5E3FF', // secondary/secondary-light
    lighter: '#FAF2FF', // secondary/secondary-lighter
    contrastText: '#000000',
  },
  success: {
    darker: '#268764', // success/success-darker
    dark: '#2FA77B', // success/success-dark
    main: '#38C793', // success/success-base
    light: '#C7EFE1', // success/success-light
    lighter: '#EBF9F4', // success/green-lighter
    contrastText: '#FFFFFF',
  },
  error: {
    darker: '#98132C', // error/error-darker
    dark: '#BB1837', // error/error-dark
    main: '#DF1C41', // error/error-base
    light: '#F9D2D9', // error/error-light
    lighter: '#FDEFF2', // error/red-lighter
    contrastText: '#FFFFFF',
  },
  warning: {
    darker: '#A4541E', // warning/warning-darker
    dark: '#CA6725', // warning/warning-dark
    main: '#F17B2C', // warning/warning-base
    light: alpha('#F17B2C', 0.1), // warning/warning-light
    lighter: alpha('#F17B2C', 0.05), // warning/warning-lighter
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#000000', // text & icons/primary
    secondary: alpha('#000000', 0.56), // text & icons/secondary
    placeholder: alpha('#000000', 0.44), // text & icons/placeholder
    disabled: alpha('#1D2715', 0.28), // text & icons/disabled
  },
  action: {
    active: alpha('#000000', 0.05), // background/component/primary
    hover: alpha('#000000', 0.1), // background/component/hovererd
    focus: alpha('#000000', 0.15), // background/component/pressed
    selected: alpha('#000000', 0.05), // background/component/selected
    disabled: alpha('#000000', 0.05), // background/component/disabled
  },
  background: {
    default: '#F5F6F6', // background/bg/primary
    light: '#FFFFFF', // background/bg/container
    paper: '#FFFFFF', // background/bg/pure
  },
  divider: alpha('#000000', 0.05),
  additional: {
    layerBorder: '#FFFFFF', // additional/layer-border
    pureBlack: '#000000', // additional/pure-black
    gradient: 'linear-gradient(180deg, #B2EF82 0%, #94D95E 100%)', // additional/gradient
  },
}

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  common: {
    black: '#202020', // base/black
    white: '#FFFFFF', // base/white
  },
  primary: {
    darker: '#30967E', // primary/primary-darker
    dark: '#24826C', // primary/primary-dark
    main: '#1C715D', // primary/primary-base
    light: alpha('#1C715D', 0.1), // primary/primary-light
    lighter: alpha('#1C715D', 0.05), // primary/primary-lighter
    contrastText: '#000000',
  },
  secondary: {
    darker: '#934ABF', // secondary/secondary-darker
    dark: '#A057CC', // secondary/secondary-dark
    main: '#A972CA', // secondary/secondary-base
    light: alpha('#A972CA', 0.1), // secondary/secondary-light
    lighter: alpha('#A972CA', 0.05), // secondary/secondary-lighter
    contrastText: '#1D2715',
  },
  success: {
    darker: '#78D9B6', // success/success-darker
    dark: '#58D0A4', // success/success-dark
    main: '#69DBB1', // success/success-base
    light: alpha('#38C793', 0.1), // success/success-light
    lighter: alpha('#38C793', 0.05), // success/green-lighter
    contrastText: '#FFFFFF',
  },
  error: {
    darker: '#E9657E', // error/error-darker
    dark: '#E4405F', // error/error-dark
    main: '#F54667',
    light: alpha('#DF1C41', 0.1), // error/error-light
    lighter: alpha('#DF1C41', 0.05), // error/red-lighter
    contrastText: '#FFFFFF',
  },
  warning: {
    darker: '#F5A570', // warning/warning-darker
    dark: '#F3904E', // warning/warning-dark
    main: '#FDA366', // warning/warning-base
    light: alpha('#F17B2C', 0.1), // warning/warning-light
    lighter: alpha('#F17B2C', 0.05), // warning/warning-lighter
    contrastText: '#FFFFFF',
  },
  text: {
    primary: alpha('#FFFFFF', 0.9), // text & icons/primary
    secondary: alpha('#FFFFFF', 0.56), // text & icons/secondary
    placeholder: alpha('#FFFFFF', 0.44), // text & icons/placeholder
    disabled: alpha('#FFFFFF', 0.28), // text & icons/disabled
  },
  action: {
    active: alpha('#FFFFFF', 0.05), // background/component/primary
    hover: alpha('#FFFFFF', 0.1), // background/component/hovererd
    focus: alpha('#FFFFFF', 0.15), // background/component/pressed
    selected: alpha('#FFFFFF', 0.05), // background/component/selected
    disabled: alpha('#FFFFFF', 0.05), // background/component/disabled
  },
  background: {
    default: '#111111', // background/bg/primary
    light: '#272727', // background/bg/container
    paper: '#272727', // background/bg/pure
  },
  divider: alpha('#FFFFFF', 0.05),
  additional: {
    layerBorder: alpha('#FFFFFF', 0.05), // additional/layer-border
    pureBlack: alpha('#FFFFFF', 0.1), // additional/pure-black
    gradient: 'linear-gradient(180deg, #B2EF82 0%, #94D95E 100%)', // additional/gradient
  },
}
