import { Stack, StackProps, useTheme } from '@mui/material'

import { Icons } from '@/enums'
import { UiIcon } from '@/ui'

interface UserAvatarProps extends StackProps {
  size?: number
}

const UserAvatar = ({ size = 5, ...rest }: UserAvatarProps) => {
  const { palette, spacing } = useTheme()

  return (
    <Stack
      {...rest}
      bgcolor={palette.action.active}
      borderRadius={100}
      p={2}
      width={spacing(size + 4)}
      height={spacing(size + 4)}
    >
      <UiIcon name={Icons.User} size={size} />
    </Stack>
  )
}

export default UserAvatar
