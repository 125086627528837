import { Divider, IconButton, Stack, useTheme } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ProfileMenu } from '@/common'
import { Icons, RoutePaths } from '@/enums'
import { uiStore, useUiState } from '@/store'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'

interface NavbarLinkProps {
  to: RoutePaths
  icon: Icons
  activeIcon: Icons
}

const NavbarLink = ({ to, icon, activeIcon }: NavbarLinkProps) => {
  const location = useLocation()
  const { palette, spacing } = useTheme()

  const isRouteActive = useMemo(() => {
    return location.pathname.startsWith(to)
  }, [location.pathname, to])

  return (
    <NavLink to={to}>
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          width: spacing(10),
          height: spacing(10),
          borderRadius: 250,
          backgroundColor: isRouteActive ? palette.additional.pureBlack : 'transparent',
          color: isRouteActive ? palette.common.white : palette.text.primary,
          transition: Transitions.Default,
          '&:hover': {
            backgroundColor: isRouteActive ? palette.additional.pureBlack : palette.action.hover,
          },
        }}
      >
        <UiIcon name={isRouteActive ? activeIcon : icon} size={5} />
      </Stack>
    </NavLink>
  )
}

const AppNavbar = () => {
  const { palette, breakpoints } = useTheme()
  const { paletteMode } = useUiState()

  const navbarItems = useMemo(
    () => [
      {
        route: RoutePaths.Events,
        icon: Icons.QrCode,
        activeIcon: Icons.QrCode,
      },
      {
        route: RoutePaths.Leaderboard,
        icon: Icons.Trophy,
        activeIcon: Icons.TrophyFill,
      },
      {
        route: RoutePaths.DailyQuestions,
        icon: Icons.Question,
        activeIcon: Icons.QuestionFill,
      },
    ],
    [],
  )

  const togglePaletteMode = useCallback(() => {
    uiStore.setPaletteMode(paletteMode === 'dark' ? 'light' : 'dark')
  }, [paletteMode])

  return (
    <Stack
      justifyContent='space-between'
      alignItems='center'
      py={6}
      px={4}
      borderRight={1}
      borderColor={palette.divider}
      sx={{
        [breakpoints.down('md')]: {
          flexDirection: 'row',
          py: 4,
        },
      }}
    >
      <Stack
        spacing={4}
        sx={{
          [breakpoints.down('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
        }}
      >
        <Stack component={NavLink} to={RoutePaths.Root} alignItems='center'>
          <UiIcon name={Icons.App} size={8} sx={{ color: palette.text.primary }} />
        </Stack>
        <Divider />
        <Stack
          spacing={4}
          p={1}
          sx={{
            [breakpoints.down('md')]: {
              flexDirection: 'row',
            },
          }}
        >
          {navbarItems.map(({ route, icon, activeIcon }, idx) => (
            <NavbarLink key={idx} to={route} icon={icon} activeIcon={activeIcon} />
          ))}
        </Stack>
      </Stack>

      <Stack
        spacing={4}
        sx={{
          [breakpoints.down('md')]: {
            flexDirection: 'row',
          },
        }}
      >
        <IconButton onClick={togglePaletteMode}>
          <UiIcon name={paletteMode === 'dark' ? Icons.Moon : Icons.Sun} size={5} />
        </IconButton>
        <ProfileMenu />
      </Stack>
    </Stack>
  )
}

export default AppNavbar
